$BG_COLOR: #11121c;
$NEUTRALS_0: white;
$NEUTRALS_2: #959d9e;
$NEUTRALS_6: #31333e;
$NEUTRALS_7: #20222c;
$NEUTRALS-9: #20222c;
$BLUE_1: #248ded;
$PRIMARY_GRADIENT: linear-gradient(91.27deg, #618dff 0%, #05d7e4 100%);
$BORDER_GRADIENT: linear-gradient(91.27deg, #4c4f53 0%, #1d3f3e 100%);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow: hidden;
  touch-action: manipulation;
  background: $BG_COLOR;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  color: $NEUTRALS-0 !important;
  -webkit-text-fill-color: $NEUTRALS-0 !important;
}

body {
  min-height: 100vh;
  background-image: url('./images/cryptorio-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom left;
}

body,
input,
button,
a {
  font-family: 'Rubik', sans-serif;
}

.container {
  min-height: 100vh;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  position: absolute;
  top: 40px;
  width: 118px;
  height: 25px;
}

.description {
  font-size: 18px;
  color: $NEUTRALS_2;
  font-weight: 400;
  margin-bottom: 32px;
  text-align: center;
  @media screen and (min-width: 600px) {
    margin-bottom: 28px;
  }
}

.title {
  font-size: 28px;
  color: $NEUTRALS_0;
  font-weight: 500;
  margin-bottom: 60px;
  text-align: center;
  @media screen and (min-width: 600px) {
    margin-bottom: 40px;
  }
}

.hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

.content-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 600px) {
    transform: translateY(-50%);
  }
}

.input {
  height: 48px;
  outline: none;
  box-shadow: none;
  border: 1px solid $NEUTRALS-6;
  border-radius: 7px;
  padding: 0 16px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  color: $NEUTRALS-0;
  width: 100%;
  transition: border-color 0.2s ease;
  &::placeholder {
    font-size: 14px;
    font-weight: 400;
  }
  &:focus {
    border-color: $BLUE_1;
  }
  @media screen and (min-width: 600px) {
    font-size: 14px;
    width: 360px;
  }
}

.button {
  position: relative;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 7px;
  width: 100%;
  background-color: transparent;
  &:hover {
    &::before {
      opacity: 1;
    }
  }
  &:focus:not(:hover) {
    &::before {
      opacity: 1;
      background: $BG_COLOR;
    }
    &::after {
      opacity: 1;
    }
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    background: $BORDER_GRADIENT;
    z-index: 1;
    border-radius: 9px;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    background: $PRIMARY_GRADIENT;
    z-index: 0;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  &__content {
    width: 100%;
    position: relative;
    z-index: 2;
    height: 48px;
    border-radius: inherit;
    display: inline-flex;
    background: $PRIMARY_GRADIENT;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 400;
    color: $NEUTRALS-0;
  }
  @media screen and (min-width: 600px) {
    width: initial;
  }
}

.form {
  width: 100%;
  &__input {
    margin-bottom: 24px;
    @media screen and (min-width: 600px) {
      width: 360px;
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
  @media screen and (min-width: 600px) {
    display: flex;
  }
}

.notification {
  position: absolute;
  top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $NEUTRALS-9;
  border: 1px solid $NEUTRALS-7;
  border-radius: 10px;
  padding: 20px 32px;
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.16);
  visibility: hidden;
  transform: translateY(-200%);
  max-width: calc(100vw - 32px);
  transition-property: visibility transform;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  &__text {
    color: $NEUTRALS-0;
    font-size: 14px;
    font-weight: 500;
  }
  &.open {
    visibility: visible;
    transform: translateY(0);
  }
  @media screen and (min-width: 700px) {
    white-space: nowrap;
    transform: translateY(200%);
    bottom: 40px;
    top: auto;
  }
}
